<template>
  <div class="animated fadeIn">
    <div>

    <warehouse-table
      id="warehouse"
      resourceName="warehouseResourceList"
      :url="endpoints.findAll"
      :fields="fields"
      :warehouse="showAllocation"
    >

    </warehouse-table>

    </div>
  </div>
</template>

<script>
  import WarehouseTable from '@/components/tables/warehouse-table'
  import shared from '@/shared/shared';

  export default {
    name: 'warehouse',
    components: { WarehouseTable },

    data () {
      return {
        fields: [
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'actions', label: 'Ações' }
        ],
        endpoints: {
          findAll: process.env.VUE_APP_API_URL + 'stock-locales'
        }
      }
    },

    mounted: async function () {
      this.$store.commit('app/SET_CURRENT_PAGE', { title: "Lista de Armazéns", size: 4 })
    },

    methods: {
      async showAllocation (warehouse) {
        await shared.setLocalStorageObj('warehouse/warehouse', warehouse);
        this.$router.push('/warehouse/allocations/all')
      }
    }
  }
</script>

<style scoped>

</style>
