<template>
  <div>
    <b-row>
      <div class="col-12">
        <b-card>
          <b-row class="mx-md-n3 mx-1">
            <div class="col-12">
              <b-input-group>
                <b-form-input
                  v-model="filter"
                  @keyup.enter="searchButtonPressed"
                  placeholder="Procurar por..."
                />
                <b-input-group-append>
                  <b-button size="md" text="Button" variant="outline-info" @click.passive="searchButtonPressed">
                    <i class="cui-magnifying-glass"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-row>
        </b-card>
      </div>
    </b-row>

    <b-row>
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div v-if="loadingRows" class="mb-5 pb-5">
              <loading-animation />
            </div>

            <div v-if="!loadingRows && !items.length" class="mb-3">
              <div class="row bg-light mx-0 py-3">
                <div class="col text-center">
                  Nenhum Armazém encontrado
                </div>
              </div>
            </div>

            <div v-if="!loadingRows && items.length" class="mb-3">
              <div v-for="(item, index) in items" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
                <div class="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-7">
                  <span class="font-weight-bold">Nome: </span>{{item.name}}
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-5">
                  <b-button class="mr-1" @click.passive="warehouse(item)" >
                    <i class="fa fa-cube"></i> Endereços
                  </b-button>
                </div>
              </div>
            </div>

            <nav>
              <b-pagination
                v-if="totalRows"
                align="center"
                :limit="10"
                :total-rows="totalRows"
                :per-page="perPage"
                prev-text="Anterior"
                next-text="Próximo"
                v-model="currentPage"
                @change="pageChanged"
                :disabled="loadingRows"/>
            </nav>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
  import { httpClient } from '@/service'
  import Animation from '@/components/loaders/animation'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared';

  export default {
    name: 'warehouse-table',
    components: { Animation, LoadingAnimation },
    props: {
      id: {
        type: String,
        require
      },
      fields: {
        type: [Array, Object],
        default: () => [],
      },
      warehouse: {
        type: Function,
      },
      url: {
        type: String,
      },
      resourceName: {
        type: String,
      },
    },

    data: () => {
      return {
        currentPage: 1,
        perPage: 10,
        totalRows: null,
        loadingRows: false,
        items: [],
        filter: null,
        lastFilter: null
      }
    },

    mounted() {
      this.filter = shared.getLocalStorageString('warehouse/warehouseFilter');
      this.currentPage = Number(shared.getLocalStorageString('warehouse/currentPage'))

      if (!this.currentPage) {
        this.currentPage = 1;
      }

      this.getPage({
        apiUrl: this.url,
        currentPage: this.currentPage,
        perPage: this.perPage,
        sortBy: "",
        sortDesc: false
      })
    },

    methods: {
      getPage (context) {
        if (this.loadingRows) {
          return;
        }

        shared.setLocalStorageString('warehouse/currentPage', context.currentPage);
        const sortBy = context.sortBy ? `&sort=${context.sortBy},${context.sortDesc ? 'desc' : 'asc'}` : ''

        let conditions = [{
          field: 'active',
          value: true
        }];

        if (this.filter && this.filter.length) {
          conditions.push({
            field: 'name',
            conditionalOperator: 'LIKE',
            value: this.filter
          });
        }

        this.loadingRows = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-locales/condition?page=${context.currentPage - 1}&size=${context.perPage}${sortBy}`, {
          conditions: conditions
        }).then(({ data }) => {
          this.loadingRows = false;
          this.totalRows = data.data.totalElements
          this.items = data.data.content
        }).catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.items = [];
        });
      },

      pageChanged(page) {
        this.getPage({
          apiUrl: this.url,
          currentPage: page,
          perPage: this.perPage,
          sortBy: "",
          sortDesc: false
        })
      },

      searchButtonPressed() {
        shared.setLocalStorageString('warehouse/warehouseFilter', this.filter);
        this.currentPage = 1;
        this.pageChanged(this.currentPage);
      },
    },
  }
</script>

<style scoped>
  .items {
    cursor: pointer;
  }
</style>
